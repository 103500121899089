import { PublicUserAttrs } from "@sparkademy/app-common/models/user";
import { FourSquaresGame, FourSquaresState } from "../models/four-squares";
import { fourSquaresCreate } from "../services/http-api-service";
import { IubendaService } from "../services/iubenda-service";

export class GamblingGameService {
  userId: string | undefined;
  game: FourSquaresGame | null = null;

  async start(
    trials: number[],
    userAttrs: PublicUserAttrs,
    cohort: string
  ): Promise<FourSquaresState | undefined> {
    const email = userAttrs.email;
    const res = await fourSquaresCreate(trials, userAttrs, cohort);
    if (res.error) {
      return;
    }

    IubendaService.submitConsent(res.id!, email, {
      location: "spark-check",
      cohort,
    });

    this.game = new FourSquaresGame(trials);
    this.userId = res.id;

    return {
      rounds: this.game.rounds,
      current: this.game.roundIndex,
      finished: !this.game.round,
    };
  }

  update(delayMs: number, choice: number | null): FourSquaresState {
    if (!this.game) {
      throw new Error("game is not started");
    }

    this.game.choose(delayMs, choice);
    return {
      rounds: this.game.rounds,
      current: this.game.roundIndex,
      finished: !this.game.round,
    };
  }
}
